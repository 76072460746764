// import { setLoading } from "@/redux/slice/loading";
import { errorToast, successToast } from "./toast";

const baseURL = `${process.env.NEXT_PUBLIC_BASE_URL}/api`;

const publicApiCall = {
  async get(url) {
    const requestHeaders = {};
    document.getElementById("overlay").style.display = "block";
    if (localStorage.getItem("accessToken")) {
      console.log(localStorage.getItem('accessToken'));
      requestHeaders["Authorization"] = localStorage.getItem("accessToken");
    }

    const response = await fetch(`${baseURL}/${url}`,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },

      })

    document.getElementById("overlay").style.display = "none";
    //console.log("res",response);
    if (!response.ok) {
      const error = await response.json();
      errorToast(error.message);
      return await Promise.reject(error);
    } else {
    }
    const data = await response.json();
    console.log("data of api is", data);
    return data;

    // .catch((error) => {
    //   errorToast("An error occurred while making the request.");
    //   return Promise.reject(error);
    // });
  },

  async post(url, data) {
    const requestHeaders = {};

    if (localStorage.getItem("accessToken")) {
      requestHeaders["Authorization"] = localStorage.getItem("accessToken");
    }

    return fetch(`${baseURL}/${url}`, {
      method: "GET", // You can change the HTTP method as needed (e.g., POST, PUT, DELETE, etc.)
      headers: new Headers(requestHeaders),
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (!response.ok) {
          const error = await response.json();
          errorToast(error.message);
          return await Promise.reject(error);
        } else {
        }

        return response.json();
      })
      .catch((error) => {
        errorToast("An error occurred while making the request.");
        return Promise.reject(error);
      });
  },
  async postPublic(url, data) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    return fetch(`${baseURL}/${url}`, {
      method: "POST", // You can change the HTTP method as needed (e.g., POST, PUT, DELETE, etc.)
      headers: new Headers({
        "Content-Type": "application/json", // Set Content-Type to JSON
      }),
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (!response.ok) {
          const error = await response.json();
          errorToast(error.message);
          return await Promise.reject(error);
        } else {
          const success = await response.json();
          successToast(success.message);
          return success;
        }
      })
      .catch((error) => {
        errorToast("An error occurred while making the request.");
        return Promise.reject(error);
      });
  },

  async put(url, data) {
    const requestHeaders = {};

    if (localStorage.getItem("accessToken")) {
      requestHeaders["Authorization"] = localStorage.getItem("accessToken");
    }

    return fetch(`${baseURL}/${url}`, {
      method: "put", // You can change the HTTP method as needed (e.g., POST, PUT, DELETE, etc.)
      headers: new Headers(requestHeaders),
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (!response.ok) {
          const error = await response.json();
          errorToast(error.message);
          return await Promise.reject(error);
        } else {
        }

        return response.json();
      })
      .catch((error) => {
        errorToast("An error occurred while making the request.");
        return Promise.reject(error);
      });
  },
};

export default publicApiCall;
