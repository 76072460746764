// import ApiCall from "@/utils/axiosCall";
import publicApiCall from "../utils/axiosCall";


// export const getAllComments = (campaignId) => {
//   return ApiCall.get(`campaign/${campaignId}/comments?limit=30&offset=0`);
// };

// export const getAllNftfromBrands = (id, offset, isSold, All) => {
//   if (!isSold) {
//     isSold = false;
//   }
//   return ApiCall.get(
//     `app/impact-associated-Brands-assets/${id}?limit=12&offset=${offset}&isSold=${isSold}&all=${All}`
//   );
// };
export const getAllNftPaginate = (id, offset, isSold, All) => {
  if (!isSold) {
    isSold = false;
  }
  return publicApiCall.get(
    `app/impact-associated-assets/${id}?limit=12&offset=${offset}&isSold=${isSold}&all=${All}`
  );
};
// export const postComment = (id, comment) => {
//   return ApiCall.post(`campaign/${id}/comment`, {
//     comment,
//   });
// };
export const addToFav = (id) => {
  return publicApiCall.put(`/user/marketplace/add-to-favourites/${id}`);
};

// export const getAllCampaign = () => {
//   return ApiCall.get(`app/active-campaigns`);
// };
// export const getAllCampaignMethod = () => {
//   return ApiCall.get(`app/all-campaigns`);
// };
// export const getFeatureCampaign = () => {
//   return ApiCall.get(`app/active-campaigns?isFeatured=true`);
// };

// export const getAllSuperCampaign = () => {
//   return ApiCall.get(`app/super-campaigns`);
// };
export const getAllSubCampaign = (id) => {
  return publicApiCall.get(`/app/sub-campaigns/${id}`);
};

export const getNftAssetHistory = (id) => {
  return publicApiCall.get(`/app/asset-history/${id}?limit=3&offset=0`);
};

// export const getMyCampaignDetail = (campaignId) => {
//   return ApiCall.get(`campaign/campaign-request-by-id/${campaignId}`);
// };

export const getNftDetail = (nftId) => {
  return publicApiCall.get(`app/asset-details/${nftId}`);
};

// export const buyNft = (nftId) => {
//   return ApiCall.post(`user/marketplace/buy-nft/${nftId}`);
// };

// export const featureBrands = () => {
//   return ApiCall.get(`app/active-brands?isFeatured=true`);
// };
export const getAllBrands = () => {
  return publicApiCall.get(`app/active-brands?isFeatured=false`);
};
// export const featureCauses = () => {
//   return ApiCall.get(`app/active-causes?isFeatured=true`);
// };
export const getAllCauses = () => {
  return publicApiCall.get(`app/active-causes?isFeatured=false`);
};
export const getAllSocial = (id) => {
  return publicApiCall.get(`gallery/campaign-posts/${id}`);
};
// export const getAllInfluencers = () => {
//   return ApiCall.get(`app/influencers?isFeatured=false`);
// };
// export const getSingleInfluencer = (id) => {
//   return ApiCall.get(`app/influencer-detail/${id}`);
// };
// export const getAllUserCampaign = (id) => {
//   return ApiCall.get(`app/active-campaigns/${id}`);
// };

// Next JS Application Campaigns APIS
// API Call of the Browse Campaigns
export const getAllCampaigns = () => {
  return publicApiCall.get("app/all-campaigns");
};

export const getSuperCampaigns = () => {
  return publicApiCall.get("app/super-campaign-names");
};

