import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Likes from "../likes";
import Auctions_dropdown from "../dropdown/Auctions_dropdown";
import { useDispatch, useSelector } from "react-redux";
import { buyModalShow } from "../../redux/counterSlice";
import { addToFav, getAllNftPaginate } from "../../services/campaign";
import { useRouter } from "next/router";
import { fetchGetAllNftPaginate, stateCampaignNft } from "../../redux/apiSlice";
import { successToast } from "../../utils/toast";
export const baseURL = `${process.env.BASE_URL}/`;

const CategoryItem = () => {
  const { campaignNft, isLoading } = useSelector((state) => state.api);
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    const fetchData = () => {
      if (router.isReady) {
        const { campaignId } = router.query;
        if (campaignId === undefined) return null;
        try {
          dispatch(
            fetchGetAllNftPaginate({
              id: campaignId,
              offset: 0,
              isSold: false,
              All: false,
            })
          );
        } catch (error) {
          console.error("API request failed:", data.error);
        }
      }
    };

    fetchData();
  }, [router.isReady, dispatch, router.query]);

  return (
    <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        campaignNft?.campaigns?.map((item, index) => {
          return (
            <article key={index}>
              <div className="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
                <figure className="relative">
                  <Link href={`/impact-token-detail/${item._id}`}>
                    <Image
                      width={230}
                      height={230}
                      src={baseURL + item.imageUrl}
                      alt="item 5"
                      className="w-full h-[230px] rounded-[0.625rem] object-cover"
                    />
                  </Link>
                  <div
                    onClick={() => {
                      addToFav(item._id).then((res) => {
                        successToast(res.data.message);
                      });
                    }}
                  >
                    <Likes like="15" />
                  </div>

                  {/* <div className="absolute left-3 -bottom-3">
                    <div className="flex -space-x-2">
                      <Link href={`/404`}>
                        <Tippy content={<span>creator: Sussygirl</span>}>
                          <Image
                            width={21}
                            height={21}
                            src="/images/avatars/creator_1.png"
                            alt="creator"
                            className="dark:border-jacarta-600 hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-2 border-white"
                          />
                        </Tippy>
                      </Link>
                      <Link href={`/404`}>
                        <Tippy content={<span>creator: Sussygirl</span>}>
                          <Image
                            width={21}
                            height={21}
                            src="/images/avatars/owner_1.png"
                            alt="owner"
                            className="dark:border-jacarta-600 hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-2 border-white"
                          />
                        </Tippy>
                      </Link>
                    </div>
                  </div> */}
                </figure>
                <div className="mt-7 flex items-center justify-between">
                  <Link href={`/impact-token-detail/${item._id}`}>
                    <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                      {item.itemName}
                    </span>
                  </Link>

                  <Auctions_dropdown
                    share_to={`/impact-token-detail/${item._id}`}
                    classes="dark:hover:bg-jacarta-600 dropup hover:bg-jacarta-100 rounded-full "
                  />
                </div>

                <div className="mt-2 text-sm">
                  <span className="dark:text-jacarta-200 text-jacarta-700 mr-1">
                    Creator: {item.creator.username}
                  </span>
                </div>
                <div className="mt-2 text-sm">
                  <span className="dark:text-jacarta-200 text-jacarta-700 mr-1">
                    Price: ${item.priceUsd}
                  </span>
                </div>
                <div className="flex items-center justify-center">
                  <button
                    className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all mt-4"
                    onClick={() => dispatch(buyModalShow(item))}
                  >
                    BUY NOW
                  </button>
                </div>
              </div>
            </article>
          );
        })
      )}
    </div>
  );
};

export default CategoryItem;
